import React from 'react';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import { autorun } from 'mobx';
import { useWinstonLogger } from 'winston-react';

import { useStore } from 'store/storeUtils.js';
import PrivateRoute from 'routes/PrivateRoute';
import RouteConsts from './routes/Routes.jsx';
import Client from 'client/Client';
import DetectUrl from 'utils/DetectUrl';
import CrmLoader from 'components/common/CrmLoader';
import { PERMISSION } from 'constants/PagePermission.jsx';
import langLocal from 'translations/languages.json';
import SSOComponent from 'client/SSOComponent';
import ServiceWorkerWrapper from 'components/common/ServiceWorkerWrapper';
import LogoutListener from 'components/specific/LogoutListener';
import JwtChecker from 'utils/JwtChecker.js';
import GlobalStyles from 'utils/GlobalStyles.js';

export const App = () => {
  const store = useStore();
  const logger = useWinstonLogger();
  const [loading, setLoading] = React.useState(true);
  //obtain browser language
  var lang = navigator.language.split(/[-_]/)[0];
  const defLanguage = 'it';

  if (store.loggedUser && store.loggedUser.language) {
    lang = store.loggedUser.language;
  }
  if (lang !== defLanguage && lang !== 'en') {
    lang = defLanguage;
  }

  const [locale, setLocale] = React.useState(defLanguage);

  const mess = [];
  mess[defLanguage] = langLocal[defLanguage];

  const [message, setMessage] = React.useState(mess);

  fetch(process.env.REACT_APP_BUCKET_BASEURL + process.env.REACT_APP_TRANSLATION_URL, {
    cache: 'no-cache',
  })
    //fetch('src/translations/languages.json')
    .then((response) => response.json())
    .then((jsonData) => {
      const mess = message;
      mess[lang] = jsonData[lang];
      mess[defLanguage] = jsonData[defLanguage];
      setMessage(mess);
      setLocale(lang);
      moment.locale(lang);
      store.setSystemLanguage(lang);
      setLoading(false);
    })
    .catch((error) => {
      logger.error(error);
      const mess = message;
      mess[defLanguage] = langLocal[defLanguage];
      setMessage(mess);
      setLocale(defLanguage);
      moment.locale(defLanguage);
      store.setSystemLanguage(defLanguage);
      setLoading(false);
    });

  React.useEffect(() =>
    autorun(() => {
      if (
        (store.loggedUser &&
          store.loggedUser.language &&
          store.loggedUser.language === store.systemLanguage) ||
        !store.loggedUser ||
        !store.loggedUser.language
      ) {
        return;
      }

      setLoading(true);

      lang = store.loggedUser.language;

      if (lang !== defLanguage && lang !== 'en') {
        lang = defLanguage;
      }

      if (!mess[lang]) {
        fetch(process.env.REACT_APP_BUCKET_BASEURL + process.env.REACT_APP_TRANSLATION_URL, {
          cache: 'no-cache',
        })
          //fetch('/translations/languages.json')
          .then((response) => response.json())
          .then((jsonData) => {
            const mess = message;
            mess[lang] = jsonData[lang];
            mess[defLanguage] = jsonData[defLanguage];
            setMessage(mess);
            setLocale(lang);
            moment.locale(lang);
            store.setSystemLanguage(lang);
            setLoading(false);
          })
          .catch((error) => {
            logger.error(error);
            const mess = message;
            mess[defLanguage] = langLocal[defLanguage];
            setMessage(mess);
            setLocale(defLanguage);
            moment.locale(defLanguage);
            store.setSystemLanguage(defLanguage);
            setLoading(false);
          });
      } else {
        setLocale(lang);
        moment.locale(lang);
        store.setSystemLanguage(lang);
        setLoading(false);
      }
    }),
  );

  return (
    <IntlProvider
      messages={langLocal[locale]} //TODO messages={message[locale]}
      locale={locale}
      key={locale}
      defaultLocale="en"
      onError={(err) => {
        if (err.code === 'MISSING_TRANSLATION') {
          //console.warn("Missing translation", err.message);
          return;
        } else if (err.code === 'FORMAT_ERROR') {
          logger.warn(err);
          return;
        }
        logger.error(err);
        return;
      }}
    >
      <div className="wrapper">
        <CrmLoader loading={loading} hasBackdrop={false} z />;
        <Router>
          <JwtChecker />
          <Switch>
            <Route path=":path?" component={() => <LayoutRoot />} />
          </Switch>
        </Router>
      </div>
      <ServiceWorkerWrapper />
    </IntlProvider>
  );
};

export const LayoutRoot = React.memo(() => {
  const store = useStore();
  var { path } = useParams();

  const prjInfo = useQuery(
    Client.GET_PROJECT_INFO,
    Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl()),
  );
  if (
    prjInfo &&
    !prjInfo.loading &&
    prjInfo.called &&
    prjInfo.data &&
    prjInfo.data.getProjectInfoByHost
  ) {
    store.setAssetsByObject({ ...prjInfo.data.getProjectInfoByHost });
    // set page title according to DB info (tuning key)
    document.title = store.pageTitles.followup;
  }

  const skipCondition = window.location.pathname.includes('resetPassword');
  const [loadUserInfo, userInfo] = useLazyQuery(Client.GET_USER_INFO);

  React.useEffect(() => {
    if (userInfo && !userInfo.loading && userInfo.called && userInfo.data?.getUserByJWT) {
      store.setLoggedUserJwtData({ userData: userInfo.data.getUserByJWT, checkingJwt: false });
    }
  }, [userInfo.data]);

  React.useEffect(() => {
    store.setCheckingJwt(true);
    if (store.projectId && !userInfo.called && !skipCondition) {
      loadUserInfo(Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId, skipCondition));
    }
  }, [store.projectId]);

  //define the first route available to redirect on update refresh
  const firstRoute = RouteConsts.sort((a, b) => a.order - b.order).find((el) => {
    const page = PERMISSION[el.id];
    const pagePermission =
      !page || (page && store.loggedUser && page.includes(store.loggedUser.role));
    if (pagePermission && el.id === 'home') {
      return el;
    } else if (pagePermission && el.navbar) {
      return el;
    }
  });
  //TODO: add some validation here and inform user if tenant is invalid
  return (
    <Router basename={path}>
      <GlobalStyles />
      <Switch>
        <Route exact path="/login" component={SSOComponent} />
        {/* TUTTE LE ROUTE, ORDINATE PER ORDER */}
        {RouteConsts.sort((a, b) => a.order - b.order).map((el) => {
          const page = PERMISSION[el.id];
          const pagePermission =
            !page || (page && store.loggedUser && page.includes(store.loggedUser.role));
          return (
            <PrivateRoute
              breadcrumb={el.breadcrumb}
              exact={el.exact}
              key={el.order}
              path={el.to}
              ToRender={el.component}
              title={el.title}
              goBack={el.goBack}
              navbar={
                store?.enabledSections?.length
                  ? store.enabledSections.includes(el.id) && pagePermission
                  : el.navbar && pagePermission
              }
            />
          );
        })}

        {/* DEFAULT */}
        <PrivateRoute
          path={firstRoute && firstRoute.to}
          ToRender={firstRoute && firstRoute.component}
          title={firstRoute && firstRoute.title}
          goBack={false}
        />
      </Switch>
      <LogoutListener />
    </Router>
  );
});
